/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Konağın,1905-1907 yıllarında II. Abdülhamid döneminde yapıldığı düşünülmektedir. Ancak 6 Eylül 1922 yılında,Kurtuluş Savaşı sırasında,Bilecik'ten çekilen Yunan ordusu tarafından 1956 adet evle birlikte Hükümet Konağı da yakılmıştır.Eski Hükümet Konağı'na ait Osmanlı arması halen yapıda teşhir edilmektedir. 1922-1924 yılları arasında yeniden yapılan Hükümet Konağı, 1. Ulusal Mimarlık Dönemine ait eserlerden biridir.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
